"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateOnPauseMutation = exports.useDeleteQuizMutation = exports.useLazyGetOnPauseQuizQuery = exports.useGetOnPauseQuizQuery = exports.useOverTimeMutation = exports.useGenerateNextDiagIdQuery = exports.useCompleteMutation = exports.useAddAnswerMutation = exports.useCreateDiagnosticMutation = exports.useQuizDetailQuery = exports.useDiagnosticDetailQuery = exports.useDiagnosticsQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const diagnosticApi = baseApiNew.injectEndpoints({
    endpoints: (builder) => ({
        diagnostics: builder.query({
            query: (id) => `/cms/common/diagnostics/test-type/${id}`,
        }),
        diagnosticDetail: builder.query({
            query: (id) => `/cms/common/diagnostics/${id}`,
        }),
        quizDetail: builder.query({
            query: (quizId) => `/diagnostic/${quizId}`,
        }),
        createDiagnostic: builder.mutation({
            query: (data) => ({
                url: `/diagnostic`,
                method: 'POST',
                body: data,
            }),
        }),
        addAnswer: builder.mutation({
            query: ({ quizId, complete, mode, answer, }) => ({
                url: `/diagnostic/${quizId}/complete/${complete}/mode/${mode}`,
                method: 'PATCH',
                body: answer,
            }),
        }),
        complete: builder.mutation({
            query: ({ quizId }) => {
                // console.log('quizId', quizId);
                return ({
                    url: `/diagnostic/complete/${quizId}`,
                    method: 'PATCH',
                    body: {},
                });
            },
        }),
        generateNextDiagId: builder.query({
            query: (quizId) => `/diagnostic/nextDiagId/${quizId}`,
        }),
        overTime: builder.mutation({
            query: ({ quizId, body }) => ({
                url: `/diagnostic/overTime/${quizId}`,
                method: 'POST',
                body: body,
            }),
        }),
        getOnPauseQuiz: builder.query({
            query: (id) => `/diagnostic/onPauseQuiz/${id}`,
        }),
        deleteQuiz: builder.mutation({
            query: (quizId) => ({
                url: `/diagnostic/${quizId}`,
                method: 'DELETE',
                body: {},
            }),
        }),
        updateOnPause: builder.mutation({
            query: ({ quizId, body }) => ({
                url: `/diagnostic/onPause/${quizId}`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
    overrideExisting: false,
});
exports.useDiagnosticsQuery = diagnosticApi.useDiagnosticsQuery, exports.useDiagnosticDetailQuery = diagnosticApi.useDiagnosticDetailQuery, exports.useQuizDetailQuery = diagnosticApi.useQuizDetailQuery, exports.useCreateDiagnosticMutation = diagnosticApi.useCreateDiagnosticMutation, exports.useAddAnswerMutation = diagnosticApi.useAddAnswerMutation, exports.useCompleteMutation = diagnosticApi.useCompleteMutation, exports.useGenerateNextDiagIdQuery = diagnosticApi.useGenerateNextDiagIdQuery, exports.useOverTimeMutation = diagnosticApi.useOverTimeMutation, exports.useGetOnPauseQuizQuery = diagnosticApi.useGetOnPauseQuizQuery, exports.useLazyGetOnPauseQuizQuery = diagnosticApi.useLazyGetOnPauseQuizQuery, exports.useDeleteQuizMutation = diagnosticApi.useDeleteQuizMutation, exports.useUpdateOnPauseMutation = diagnosticApi.useUpdateOnPauseMutation;
