"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strapiCustomization = exports.getDesmosSubscribed = exports.getHostName = exports.callApi = exports.getUserDetailsData = exports.getDashboardDomain = exports.getDomain = exports.isProduction = exports.getMarkedImage = exports.updateCourseDetails = exports.updateUserDetail = exports.getEnrollUrl = exports.getRegistrationUrl = exports.getUserDataById = exports.getUserById = exports.getStrapiData = exports.getAllRegisterUser = exports.getAllUser = exports.checkAdminUser = exports.getUserData = exports.getCurrentUser = exports.getCmsURL = exports.getMainUrl = void 0;
const react_cookie_1 = require("react-cookie");
const apicall_1 = require("./apicall");
let cmsUrl = null;
const init = async () => {
    cmsUrl = await getCmsURL();
};
const getDomain = () => {
    try {
        let domain = window.location.hostname;
        const urlParts = domain.split('.');
        domain = urlParts
            .slice(0)
            .slice(-(2))
            .join('.');
        return { path: '/', domain: domain };
    }
    catch (error) { }
    return null;
};
exports.getDomain = getDomain;
const getDashboardDomain = () => {
    try {
        let domain = window.location.hostname;
        const urlParts = domain.split('.');
        const segmentsToKeep = urlParts.length > 2 ? 2 : 1;
        domain = urlParts.slice(-segmentsToKeep).join('.');
        return { path: '/', domain: domain };
    }
    catch (error) { }
    return null;
};
exports.getDashboardDomain = getDashboardDomain;
const getHostName = (withPrefix) => {
    var _a;
    const loc = window.location;
    const mode = loc.hostname === 'localhost' ? 'development' : 'production';
    const urlParts = loc.host.split('.');
    const domain = getDomain();
    const isWww = urlParts.length > 0 && ((_a = urlParts[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'www' ? true : false;
    const prodURL = isWww ? (withPrefix ? `www.${domain === null || domain === void 0 ? void 0 : domain.domain}` : domain === null || domain === void 0 ? void 0 : domain.domain) : `${loc.host}`;
    console.log('prod URL:', prodURL);
    const host = mode === 'development' ? 'localhost' : prodURL;
    return host;
};
exports.getHostName = getHostName;
const getMainUrl = async () => {
    try {
        const currentUrl = window.location.href;
        const isLocal = currentUrl.includes('localhost');
        const hostName = getHostName(true);
        const hostList = hostName === null || hostName === void 0 ? void 0 : hostName.split('.');
        const hostListNew = hostList.filter((item) => item !== 'dashboard');
        const host = hostListNew.join('.');
        const prodURL = `${window.location.protocol}//${host}`;
        const mainUrl = isLocal ? 'http://localhost:3000' : prodURL;
        return mainUrl;
    }
    catch (error) { }
    return null;
};
exports.getMainUrl = getMainUrl;
const parseJwt = (token) => {
    var _a;
    try {
        const base64Url = token.split('.')[1];
        const base64 = (_a = base64Url === null || base64Url === void 0 ? void 0 : base64Url.replace(/-/g, '+')) === null || _a === void 0 ? void 0 : _a.replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    catch (error) { }
    return null;
};
const callApi = async (url, method, body) => {
    var _a, _b;
    const cookies = new react_cookie_1.Cookies();
    const access_token = cookies.get('access_token');
    const options = {
        method: method || 'GET',
        headers: {
            Authorization: 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
    };
    if (options.method === 'POST' || options.method === 'PUT') {
        options['body'] = body ? JSON.stringify(body) : '';
    }
    try {
        const response = await fetch((0, apicall_1.getApiUrl)(url), options);
        if (response.status === 403 || response.status === 401) {
            const retryResponse = await retryCallApi(url, options);
            return retryResponse;
        }
        return response;
    }
    catch (error) {
        if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 403 || ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
            const retryResponse = await retryCallApi(url, options);
            return retryResponse;
        }
        return Promise.reject(error);
    }
};
exports.callApi = callApi;
const retryCallApi = async (url, options) => {
    const isSucess = await getRefreshToken();
    if (isSucess) {
        const response = await fetch((0, apicall_1.getApiUrl)(url), options);
        return response;
    }
    else {
        redirectLogin();
        return Promise.reject('Authorization failed');
    }
};
const getRefreshToken = async () => {
    try {
        const cookies = new react_cookie_1.Cookies();
        const refresh_token = cookies.get('refresh_token');
        const access_token = cookies.get('access_token');
        const token = parseJwt(access_token);
        if (refresh_token && token.email) {
            const options = {
                method: 'POST',
                body: JSON.stringify({
                    email: token.email,
                    refreshtoken: refresh_token,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await fetch((0, apicall_1.getApiUrl)(`auth/refreshtoken`), options);
            if ((response === null || response === void 0 ? void 0 : response.status) === 200 || (response === null || response === void 0 ? void 0 : response.status) === 201) {
                const result = await response.json();
                const domain = getDomain();
                cookies.remove('access_token');
                cookies.remove('refresh_token');
                cookies.set('access_token', result.access_token, domain);
                cookies.set('refresh_token', result.refresh_token, domain);
                return true;
            }
        }
    }
    catch (error) {
        console.log('Refresh Token Error: ', error);
    }
    return false;
};
const redirectLogin = async () => {
    var _a;
    const cookies = new react_cookie_1.Cookies();
    const currentUrl = window.location.href;
    const redirectURI = await getMainUrl();
    const redirect_url = `${redirectURI}/login?redirect_url=${encodeURIComponent(currentUrl)}`;
    const domain = getDomain();
    cookies.remove('access_token');
    cookies.remove('refresh_token');
    cookies.remove('access_token', domain);
    cookies.remove('refresh_token', domain);
    (_a = window.location) === null || _a === void 0 ? void 0 : _a.replace(redirect_url);
};
const getCmsURL = async () => {
    if (cmsUrl) {
        return cmsUrl;
    }
    try {
        const response = await callApi(`auth/getcmsurl`);
        if (response.status === 200) {
            const data = await response.json();
            cmsUrl = data.contentUrl;
            return data.contentUrl;
        }
        else {
            console.error('Failed to get cms url');
        }
    }
    catch (error) {
        console.error('Failed to get cms url');
    }
    return null;
};
exports.getCmsURL = getCmsURL;
const getCurrentUser = async () => {
    try {
        const cookies = new react_cookie_1.Cookies();
        const access_token = cookies.get('access_token');
        if (access_token) {
            const token = parseJwt(access_token);
            if (token.id) {
                const user = await getUserById(token.id);
                return user || null;
            }
        }
        else {
            redirectLogin();
        }
    }
    catch (error) {
        console.error('Error:', error);
    }
    return null;
};
exports.getCurrentUser = getCurrentUser;
const getUserById = async (userId) => {
    try {
        const response = await callApi(`users/${userId}`);
        if (response.status === 200) {
            const user = await response.json();
            return user || null;
        }
        else {
            console.error('Failed to get user data');
        }
    }
    catch (error) {
        console.error('Error:', error);
    }
    return null;
};
exports.getUserById = getUserById;
const getUserDataById = async (userId) => {
    try {
        const response = await callApi(`registration/${userId}`);
        if (response.status === 200) {
            const data = await response.json();
            return data || null;
        }
        else {
            console.error('Failed to get registration data');
        }
    }
    catch (error) {
        console.error('Error:', error);
    }
    return null;
};
exports.getUserDataById = getUserDataById;
const getUserData = async () => {
    try {
        const user = await getCurrentUser();
        if (user && user.id) {
            const userData = await getUserDataById(user.id);
            return userData || null;
        }
    }
    catch (error) {
        console.log(error);
    }
    return null;
};
exports.getUserData = getUserData;
const getAllUser = async () => {
    try {
        const response = await callApi(`users`);
        if (response.status === 200) {
            const data = await response.json();
            return data || null;
        }
        else {
            console.error('Failed to get registration data');
        }
    }
    catch (error) {
        console.error('Error getting user data', error);
    }
    return null;
};
exports.getAllUser = getAllUser;
const getAllRegisterUser = async () => {
    try {
        const response = await callApi(`registration`);
        if (response.status === 200) {
            const data = await response.json();
            return data || null;
        }
        else {
            console.error('Failed to get registration data');
        }
    }
    catch (error) {
        console.error('Error getting user data', error);
    }
    return null;
};
exports.getAllRegisterUser = getAllRegisterUser;
const getUserDetailsData = async (userId) => {
    try {
        const response = await callApi(`studentdetails/${userId}`);
        if (response.status === 200) {
            const data = await response.json();
            return data || null;
        }
        else {
            console.error('Failed to get studentdetails data');
        }
    }
    catch (error) {
        console.error('Error getting user data', error);
    }
    return null;
};
exports.getUserDetailsData = getUserDetailsData;
const checkAdminUser = async (email) => {
    var _a;
    try {
        const contentAPI = await getCmsURL();
        if (contentAPI) {
            const filter = `filters[email][$eqi]=${email}`;
            const url = `${contentAPI}/api/administrators?${filter}`;
            const options = {
                method: 'GET',
            };
            const response = await fetch(url, options);
            if (response.status === 200) {
                const data = await response.json();
                return ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.length) > 0 || false;
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    return false;
};
exports.checkAdminUser = checkAdminUser;
const getStrapiData = async (path) => {
    try {
        const contentAPI = await getCmsURL();
        //For development let contentAPI= "http://localhost:1337";
        if (contentAPI) {
            const url = `${contentAPI}/api/${path}`;
            const options = {
                method: 'GET',
            };
            const response = await fetch(url, options);
            if (response.status === 200) {
                const data = await response.json();
                return (data === null || data === void 0 ? void 0 : data.data) || null;
            }
            else {
                console.error('Failed to get cms data');
            }
        }
        else {
            console.error('Failed to get cms url');
        }
    }
    catch (error) {
        console.error('Failed to get cms data');
    }
};
exports.getStrapiData = getStrapiData;
const getRegistrationUrl = async (url) => {
    try {
        const currentUrl = url ? `${window.location.protocol}//${window.location.host}/${url}` : window.location.href;
        const redirectURI = await getMainUrl();
        const redirect_url = `${redirectURI}/registration?redirect_url=${encodeURIComponent(currentUrl)}`;
        return redirect_url;
    }
    catch (error) { }
    return null;
};
exports.getRegistrationUrl = getRegistrationUrl;
const getEnrollUrl = async () => {
    try {
        const redirectURI = await getMainUrl();
        const redirect_url = `${redirectURI}/enroll?from=dashboard`;
        return redirect_url;
    }
    catch (error) { }
    return null;
};
exports.getEnrollUrl = getEnrollUrl;
const updateUserDetail = async (userId, body) => {
    try {
        await callApi(`registration/${userId}`, 'PUT', body);
        return true;
    }
    catch (error) {
        console.error('Error while updatting the user data', error);
        return false;
    }
};
exports.updateUserDetail = updateUserDetail;
const updateCourseDetails = async (courseId, method) => {
    var _a, _b, _c, _d;
    try {
        const filter = `filters[id][$eq]=${courseId}`;
        const data = await getStrapiData(`courses?${filter}&populate=*`);
        if (data.length > 0) {
            if (method === 'Add' && ((_b = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.isFull)) {
            }
            else {
                const availableSeat = Number((_d = (_c = data[0]) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.availableSeat) || null;
                let aSeat = 0;
                if (availableSeat && availableSeat > 0) {
                    aSeat = method === 'Add' ? availableSeat - 1 : method === 'Delete' ? availableSeat + 1 : availableSeat;
                }
                const isFull = aSeat === 0;
                const contentAPI = await getCmsURL();
                if (contentAPI) {
                    const apiUrl = `${contentAPI}/api/courses/${courseId}`;
                    const body = {
                        data: {
                            availableSeat: aSeat.toString(),
                            isFull: isFull,
                        },
                    };
                    const options = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: body ? JSON.stringify(body) : '',
                    };
                    const response = await fetch(apiUrl, options);
                    if (response.status === 200) {
                        return true;
                    }
                    else {
                        throw new Error(`API request failed with status: ${response.status}`);
                    }
                }
            }
        }
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
};
exports.updateCourseDetails = updateCourseDetails;
const getMarkedImage = async (data) => {
    const contentAPI = await getCmsURL();
    if (contentAPI) {
        const imageRegex = /!\[([^\]]*?)\]\(([^)]*?)\)/g;
        const newData = data === null || data === void 0 ? void 0 : data.replace(imageRegex, (match, altText, imageUrl) => {
            return `<img style='height: 90%; width: 90%;' alt='${altText}' src='${contentAPI}${imageUrl}' />`;
        });
        return newData;
    }
    else
        return data;
};
exports.getMarkedImage = getMarkedImage;
const getDesmosSubscribed = async (userId, mode) => {
    let subscribed = { isSubscribed: false, subscribedData: {}, allData: [] };
    try {
        const userData = await getUserDataById(userId);
        if (userData && (userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos) && (userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos.length) > 0) {
            userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos.sort((a, b) => a.startDate.localeCompare(b.startDate));
            for (let i of userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos) {
                let startDate = (new Date(i === null || i === void 0 ? void 0 : i.startDate)).setHours(0, 0, 0, 0);
                let endDate = i === null || i === void 0 ? void 0 : i.endDate;
                let today = (new Date()).setHours(0, 0, 0, 0);
                let todayDate = new Date();
                const offset = todayDate.getTimezoneOffset();
                todayDate = new Date(todayDate.getTime() - (offset * 60 * 1000));
                let todayDateStr = new Date(todayDate.toISOString().split('T')[0]);
                let endDateStr = new Date(endDate.slice(0, 10));
                if (mode === "admin") {
                    if ((i === null || i === void 0 ? void 0 : i.ongoing) === true && todayDateStr <= endDateStr) {
                        subscribed = { isSubscribed: true, subscribedData: i, allData: userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos };
                        break;
                    }
                }
                else {
                    if ((i === null || i === void 0 ? void 0 : i.ongoing) === true && today >= startDate && todayDateStr <= endDateStr) {
                        subscribed = { isSubscribed: true, subscribedData: i, allData: userData === null || userData === void 0 ? void 0 : userData.subscribedDesmos };
                        break;
                    }
                }
            }
        }
    }
    catch (error) { }
    return subscribed;
};
exports.getDesmosSubscribed = getDesmosSubscribed;
const isProduction = () => {
    try {
        const devDomain = ['mvpdev', 'localhost'];
        let domain = window.location.hostname;
        const urlParts = domain.split('.');
        domain = domain === 'localhost' ? urlParts[0] : urlParts[1];
        return !devDomain.includes(domain.toLocaleLowerCase());
    }
    catch (error) { }
    return false;
};
exports.isProduction = isProduction;
const strapiCustomization = async (path, data) => {
    try {
        const contentAPI = await getCmsURL();
        //For development let contentAPI= "http://localhost:1337";
        if (contentAPI) {
            const url = `${contentAPI}/api/${path}`;
            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ data: data })
            };
            const response = await fetch(url, options);
            if (response.status === 200) {
                const data = await response.json();
                return "success";
            }
            else {
                const data = await response.json();
                return " import failed. Reason: " + data.error.message;
            }
        }
        else {
            console.error('Failed to insert data');
        }
    }
    catch (error) {
        console.error('Failed to insert data');
    }
};
exports.strapiCustomization = strapiCustomization;
init();
